import PropTypes from "prop-types";
import Footer from "../Footer";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import ContentWrapper from "../../components/ContentWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "unset",
  },
  drawerPaper: {
    padding: "0px",
    backgroundColor: theme.palette.grey,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // appBarSpacer: { marginTop: '55px' },
  content: {
    flexGrow: 1,
    height: "100vh",
    paddingBottom: "30px",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const NoLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Container maxWidth="xxl" className={classes.container}>
          <ContentWrapper title="">{children}</ContentWrapper>
        </Container>
      </main>
    </div>
  );
};

NoLayout.propTypes = {
  children: PropTypes.node,
};

export default NoLayout;
