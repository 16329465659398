import { useState, createContext } from "react";
import useLogService from "../services/useLogService";

export const CurrencyContext = createContext({});

export const CurrencyProvider = ({ children }) => {
    
    const { info } = useLogService();

    const [currency, setCurrency1] = useState(null);

    const setCurrency = async (currency1, qDoc) => {

        if (currency1) {
            let tempCurrency = currency1.key;
            tempCurrency = tempCurrency === 'Local' ? "1" : `${tempCurrency}_MULT`;

            let tempCurrencySign = currency1.args;
            let currVariable = await qDoc.getVariableByName("vCurrency");
            currVariable.setStringValue(tempCurrency);

            let signVariable = await qDoc.getVariableByName("vSign")

            if (tempCurrencySign === null) {
                signVariable.setStringValue("");
            } else {
                signVariable.setStringValue(tempCurrencySign);
            }

            info('INFO', `Currency changed to ${currency1.key}`);
            setCurrency1(currency1)
        }
    }

    return (
        <CurrencyContext.Provider value={{ currency, setCurrency }}>
            {children}
        </CurrencyContext.Provider>
    );
};
