import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import SidebarLayout from "../SidebarLayout";
import SuspenseLoader from "../../components/SuspenseLoader";
import Authenticated from "../../components/Authenticated";
import NoLayout from "../NoLayout";

export const renderRoutes = (routes) => (
  <Suspense fallback="">
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            forceRefresh={true}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const generateRouteConfig = (vals) => {
  let routeArr = [];
  vals.forEach((v) => {
    let pathStr =
      v.split(" ").length > 1
        ? `${v.split(" ")[0].toLowerCase()}-${v.split(" ")[1].toLowerCase()}`
        : v.toLowerCase();
    routeArr.push({
      exact: true,
      path: `/${pathStr}`,
      component: lazy(() => import(`../../content/${v}`)),
    });
    routeArr.push({
      exact: true,
      path: `/bm/${pathStr}`,
      component: () => <Redirect to={`/${pathStr}`} />,
    });
  });
  return routeArr;
};

export const getRoutes = () => {
  return [
    {
      path: [
        "/dashboard",
        "/performance-summary",
        "/style-detail",
        "/door-detail",
        "/geographic",
        "/trending",
        "/adhoc",
        "/reports",
        "/data-availability",
        "/bm/dashboard",
        "/bm/performance-summary",
        "/bm/style-detail",
        "/bm/door-detail",
        "/bm/geographic",
        "/bm/trending",
        "/bm/adhoc",
        "/bm/reports",
        "/bm/data-availability",
      ],
      guard: Authenticated,
      layout: SidebarLayout,
      routes: generateRouteConfig([
        "Dashboard",
        "Performance Summary",
        "Style Detail",
        "Door Detail",
        "Geographic",
        "Trending",
        "AdHoc",
        "Data Availability",
        "Reports",
      ]),
    },
    {
      path: ["/", "/error", "/accesspoint"],
      layout: NoLayout,
      guard: Authenticated,
      routes: [
        {
          exact: true,
          path: "/error",
          component: lazy(() => import(`../../content/Error`)),
        },
        {
          exact: true,
          path: "/accesspoint",
          component: lazy(() => import(`../../content/AccessPoint`)),
        },
      ],
    },
  ];
};
