import { BASE_URL_AUTH } from "../utils/axios";

//hard code
export const AUTH_CONFIG = {
  domain: "skypad.auth0.com",
  clientId: "vSwKd0wY84Rn6qeyRLhlHw9QAMfDAYQB",
  callbackUrl: BASE_URL_AUTH,
  clientName: "Skypad3.0",
  BASE_URL: BASE_URL_AUTH,
};
