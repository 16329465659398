import getDimensions from "./getDimensions";
import getDimensionsDA from "./getDimensionsDA";

const resetNavState = (configData) => {

  let defaultTD = configData.find(x => x.type === "AdHoc" && x.subtype === "Timeframe").value[0];
  let defaultTrend = configData.find(x => x.type === "Trend" && x.subtype === "Default Dim").value[0].key;
  let defaultDA = configData.find(x => x.type === "AdHoc" && x.subtype === "Timeframe").value[0];

  let sdDim = getDimensions(configData.filter((x) => x.type === "Style Detail"), undefined, defaultTD);
  let ddDim = getDimensions(configData.filter((x) => x.type === "Door Detail"), undefined, defaultTD);

  let navStates = {
    unit: "Dollars",
    timeDimension: defaultTD,
    gridLength: "All",
    multiSelectTimeDimension: [defaultTD],
    defaultTD: defaultTD,
    geotype: "Stockists by Sales $",
    timeTypeSelection: defaultTrend,
    dataAvailTimeTypeSelection: "Week #",
    trendingChartType: "Pivot",
    dataAvailabilityReport: "Data Availability",
    gridTypeSD: "Straight",
    gridTypeDD: "Straight",
    gridTypeAH: "Straight",
    tlChartVal: ["Sales Units", "Sales Dollars"],
    StyleDetailsGridStats: JSON.stringify({
      selCol: sdDim,
      leftDim: 0,
      page: 0,
      limit: 100,
    }),
    DoorDetailsGridStats: JSON.stringify({
      selCol: ddDim,
      leftDim: 0,
      page: 0,
      limit: 100,
    }),
    TrendingGridStats: JSON.stringify({ selCol: [1001, 1002], leftDim: 0 }),
    DataAvailabiltyGridStats: JSON.stringify({
      selCol: getDimensionsDA(configData.filter((x) => x.type === "DataAvail")),
      leftDim: 0,
      page: 0,
      limit: 100,
      rs: ['1#', 2, 3, 4, 5, 6, 7],
      ups: ['1#', 2, 3, 4, 5, 6, 7, 8, 9],
      align: ['1#', 2, 3, 4, 5, 6, 7],
      md: ['1#', 2, 3, 4, 5, 6, 7, 8, 9]
    }),
    AdHocGridStats: JSON.stringify({
      selCol: [],
      leftDim: 0,
      page: 0,
      limit: 100,
    }),
    isPerChg: false,
  };
  sessionStorage.removeItem("sStraightTableColumns");
  sessionStorage.removeItem("dStraightTableColumns");
  sessionStorage.removeItem("aStraightTableColumns");
  sessionStorage.setItem("navStates", JSON.stringify(navStates));
};

export default resetNavState;
