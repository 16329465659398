import { useState, createContext, useContext } from "react";
import { ApiContext } from "./ApiContext";
import resetNavState from "../utils/resetNavState";

export const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const { getConfig, getMetric } = useContext(ApiContext);
  const [configVal, setConfigVal] = useState();

  const getConfigValForApp = async (app, id) => {
    let response = await getConfig(app, id);
    let response2 = await getMetric();
    for (const key in response2) {
      response.push({ [key]: response2[key] });
    }

    resetNavState(response);
    setConfigVal(response);
  };

  return (
    <ConfigContext.Provider value={{ configVal, getConfigValForApp }}>
      {children}
    </ConfigContext.Provider>
  );
};
