const getDimensions = (dimensions, type, defaultColumns) => {
    let configDimensions = [];
  
    dimensions.map((x) => {
      if (x.subtype === "Default Dim" || x.subtype === "Select Dim" || x.subtype === "Metrics") {
        x.value.map((d) => {
          let colIndex = d.order;
          if (type) {
            let obj = {
              order: colIndex,
              key: d.key,
              type: "D",
              displayName: d.label,
              measure: d.key,
              alwaysVisible: false,
              includeInTD: false,
              pos: 1,
            };
  
            if (x.subtype === "Default Dim") {
              obj.alwaysVisible = true;
              obj.subtype = x.subtype
            }
  
            if (x.subtype === "Metrics") {
              obj.type = "M";
              obj.pos = 0;
              obj.order = obj.order + "M";

            }
  
            configDimensions.push(obj);
          } else {
            if (x.subtype === "Default Dim" || x.subtype === "Metrics") {
              configDimensions.push(x.subtype === "Metrics" ? colIndex + "M" : colIndex);
            }
          }
        });
      }
    });

    if(!type){
      configDimensions.push(1002)
      configDimensions.sort((a,b)=> {
        return parseInt(a.order) - parseInt(b.order);
      })
    } 
    return configDimensions;
  };
  
  export default getDimensions;
  