export function getTag(userGroup) {
    let tag = "";

    if (userGroup.indexOf("Sky Internal") > -1 ||
        userGroup.indexOf("SkyPAD Demo") > -1) {
        tag = "sfa sof cos blm internal multi multiadmin nmgpremium nor fam skyalign loreal skypad3.0 UploadApp skybazaar Kering";
    } else {
        tag = "";
        for (var i = 0; i < userGroup.length; i++) {
            if (userGroup[i].includes("SFA ")) {
                tag = tag.concat("sfa ");
            } else if (userGroup[i].includes("SOF ")) {
                tag = tag.concat("sof ");
            } else if (userGroup[i].includes("COS ")) {
                tag = tag.concat("cos ");
            } else if (userGroup[i].includes("BLM ")) {
                tag = tag.concat("blm ");
            } else if (userGroup[i].includes("Multi Admin")) {
                tag = tag.concat("multiadmin ");
            } else if (userGroup[i].includes("NMG")) {
                tag = tag.concat("nmgpremium ");
            } else if (userGroup[i].includes("NOR ")) {
                tag = tag.concat("nor ");
            } else if (userGroup[i].includes("FAM ")) {
                tag = tag.concat("fam ");
            } else if (userGroup[i].includes("LOreal")) {
                tag = tag.concat("loreal ");
            } else if (userGroup[i].includes("QlikSense")) {
                tag = tag.concat("skypad3.0 ");
            } else if (userGroup[i].includes("UploadApp")) {
                tag = tag.concat("UploadApp ");
            } else if (userGroup[i].includes("SkyBazaar")) {
                tag = tag.concat("skybazaar ");
            } else if (userGroup[i].includes("Kering")) {
                tag = tag.concat("Kering ");
            } else if (userGroup[i].includes("Rejections")) {
                tag = tag.concat("skyalign ");
            } else {
                tag = tag.concat("multi ");
            }
        }
    }

    tag = tag.trim();
    return tag;
}