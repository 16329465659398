import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { InputAdornment } from "@mui/material";
import { ConfigContext, UpdateContext } from "../../contexts/context";
import resetNavState from "../../utils/resetNavState";
import { v4 as uuidv4 } from "uuid";
import {
  HighlightOff,
  Search,
  History,
  Update,
  Lock,
  LockOpen,
} from "@mui/icons-material";

import { QlikContext } from "../../contexts/QlikContext";
import { Box, ButtonGroup, Button, Tooltip } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { useSelectionObject } from "../../services/qlikDataServices/useSelectionObject";
// import { matcher, isMatch } from 'matcher';

const useStyles = makeStyles(() => ({
  root: {
    position: "sticky",
    top: 0,
    background: "white",
    borderBottom: "2px solid rgb(215, 222, 223)",
    padding: "0 5px",
    zIndex: 1,
    background: "white",
    borderBottom: "2px solid rgb(215, 222, 223)",
    "& .MuiAutocomplete-groupLabel": {
      fontSize: 14,
      fontWeight: "bold",
      color: "#464E5C",
    },
  },
  adHocSearchBox: {
    "& fieldset": {
      border: "none",
      border: "2px solid #bfbfbf",
      borderRadius: "0",
    },
    "& .searchBox div:first-child": {
      padding: "4px !important",
      backgroundColor: "#ffffff",
      borderRadius: 0,
      margin: 0,
    },
    "& .searchBox .MuiAutocomplete-endAdornment button": {
      display: "none",
    },
    "& svg": {
      color: "#a6a6a6",
    },
  },
}));

const SelectionSearch = () => {
  const classes = useStyles();

  const { qDoc } = useContext(QlikContext);
  const { configVal } = useContext(ConfigContext);
  let { update, setUpdate } = useContext(UpdateContext);
  const { toggleSelections } = useSelectionObject();

  const [locked, setLocked] = useState(null);
  const [searchValue, setSearchValue] = useState([]);
  const [searchValues, setSearchValues] = useState([]);
  const [autoKey, setAutoKey] = useState(null);

  useEffect(() => {
    const qProp = {
      qInfo: { qType: "SelectionObject" },
      qSelectionObjectDef: {},
    };
    qDoc.createSessionObject(qProp).then((qObject) => {
      qObject.getLayout().then((layout) => {
        let sel = layout.qSelectionObject.qSelections[0];

        if (sel) {
          let locked = sel.qStateCounts.qLocked == 1 ? true : false;
          setLocked(locked);
        }
      });
    });

    let searchValuesArr = [];//gets default search values from config file
    let filterSelections = configVal.filter((x) => x.type === "Filter");
    filterSelections.map((selectionGroup) => {
      selectionGroup.value.map((d) => {
        searchValuesArr.push(d.label.trim())
      })
    })
    setSearchValues(searchValuesArr)

  }, []);

  useEffect(() => {
    if (qDoc) {
      qDoc.abortModal({ qAccept: true });
      if (locked) {
        qDoc.lockAll();
      } else {
        qDoc.unlockAll();
      }
    }
  }, [locked]);

  const action = (type) => {
    if (type === "cl") {
      resetNavState(configVal);
      if (update) {
        setUpdate(false);
      } else {
        setUpdate(true);
      }

      qDoc.abortModal({ qAccept: true });
      qDoc.clearAll();
    } else if (type === "rd") {
      qDoc.forward({});
    } else if (type === "lock") {
      setLocked(!locked);
    } else if (type === "undo") {
      qDoc.abortModal({ qAccept: true });
      qDoc.back({});
    }
  };

  const clearOptions = () => {
    setSearchValue([]);
  };


  const filteredOptions = (x) => {
    if (searchValue[0] === "no_results") {
      return [{
        "value": "No options"
    }];
    } else return x;
  }

  const onChangeEvent = (event) => {
    let values = event.target.value.replaceAll('"', "");

    if (values.length > 2) {

      let values = event.target.value.split('*');
      values = values.filter((v) => v !== '');

      qDoc.searchResults(
        {
          "qOptions": {
            "qSearchFields": searchValues,
          },
          //qTerms: [event.target.value],
          qTerms: values,
          qPage: {
            qOffset: 0,
            qCount: 100,
          },
        })
        .then((x) => {
          let searchResult = x.qSearchGroupArray;
          let optionArr = [];
          if (searchResult.length > 0) {
            searchResult.forEach((sga) => {
              let label = sga.qItems[0].qIdentifier;
              sga.qItems[0].qItemMatches.forEach((qtm) => {
                // if (isMatch(qtm.qText, event.target.value) || qtm.qText.toLowerCase().includes(event.target.value.toLowerCase().trim())) {
                //   optionArr.push({ field: label, value: qtm.qText });
                // }
                optionArr.push({ field: label, value: qtm.qText });
              });
            });
            setSearchValue(optionArr);
          } else {
            setSearchValue(["no_results"]);
          }
        }).catch(e => {
          console.log(e)
        });
    } else {
      setSearchValue([]);
    }
  };

  const removeQuotesFromBeginningAndEnd = (str) => {  //removes quotes from beginning and end of string   
    if (str.charAt(0) === '"' && str.charAt(str.length - 1) === '"') {  //if string starts and ends with quotes
      return str.substring(1, str.length - 1);  //return string without quotes                                              
    }       
    return str;  //return string as it is 
  } 

  const onSearchValueSelect = (value) => {
    setAutoKey(uuidv4());
    let searchValue = removeQuotesFromBeginningAndEnd(value.value);
    toggleSelections(value.field, searchValue);
  }

  return (

    <div className={classes.root}>
      <Box display="flex" justifyContent="center" p={0.5} bgcolor="secondary">
        <ButtonGroup variant="text" sx={{ width: "100%" }}>
          <Button
            onClick={() => action("undo")}
            color="secondary"
            sx={{ width: "100%", color: "#0c121e" }}
          >
            <Tooltip title="Undo" placement="top-start">
              <History fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => action("rd")}
            color="secondary"
            sx={{ width: "100%", color: "#0c121e" }}
          >
            {" "}
            <Tooltip title="Redo" placement="top-start">
              <Update fontSize="small" />
            </Tooltip>
          </Button>
          <Button
            onClick={() => action("lock")}
            color="secondary"
            sx={{ width: "100%", color: "#0c121e" }}
          >
            {locked ? (
              <Tooltip title="Unlock Selections" placement="top-start">
                <LockOpen fontSize="small" />
              </Tooltip>
            ) : (
              <Tooltip title="Lock Selections" placement="top-start">
                <Lock fontSize="small" />
              </Tooltip>
            )}
          </Button>
          <Button
            onClick={() => action("cl")}
            color="error"
            sx={{ width: "100%", borderColor: "red" }}
          >
            {" "}
            <Tooltip title="Clear All Selections" placement="top-start">
              <HighlightOff fontSize="small" />
            </Tooltip>
          </Button>
        </ButtonGroup>
      </Box>

      <Autocomplete
        key={autoKey}
        id="search_menu"
        size="small"
        noOptionsText={
          searchValue.length === 0
            ? "Start typing to show results..."
            : searchValue[0] === "no_results"
              ? "No Options"
              : "No Options"
        }
        filterOptions={filteredOptions}
        className={classes.adHocSearchBox}
        options={searchValue}
        groupBy={(option) => option.field}
        onKeyUp={onChangeEvent}
        getOptionLabel={(option) => option.value}
        onClose={clearOptions}
        // defaultValue={{ field: 'default', value: '**' }}
        onChange={(_, _metric) => {
          if (_metric !== null) {
            onSearchValueSelect(_metric);
          }
        }}
        renderInput={(params) => (
          <TextField
            className="searchBox"
            {...params}
            label=""
            placeholder="Search"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {" "}
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectionSearch;
