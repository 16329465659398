import { useState, createContext, useRef, useContext } from "react";
import { ApiContext } from "./ApiContext";
import { useAuth0 } from "@auth0/auth0-react";
const enigma = require("enigma.js");
const schema = require("enigma.js/schemas/12.20.0.json");
const { v4 } = require("uuid");
import useLogService from "../services/useLogService";

export const QlikContext = createContext();

export const QlikProvider = ({ children }) => {
  const { info, error } = useLogService();
  const { getQlikTicket, setTicketLog, getAppList } = useContext(ApiContext);
  const { user } = useAuth0();

  const session = useRef(null);

  const [qDoc, setQDoc] = useState(null);
  const [qlikApps, setAppList] = useState();
  const [userAccess, setUserAccess] = useState();

  const connectQlik = async (response, docID) => {
    const qsHost = `wss://cloudanalytics.skypad.biz`;
    let qlikTicketString = `&QlikTicket=${response}`;
    session.current = enigma.create({
      schema,
      url: `${qsHost}/webticket/app/${docID ? docID : "engineData"}/ttl/1800?reloadURI=${encodeURIComponent(window.location.origin)}${qlikTicketString}`,
      createSocket: (url) => new WebSocket(url),
      responseInterceptors: [
        {
          onRejected: function retryAbortedError(sessionReference, request, error,) {
            if (error.code !== schema.enums.LocalizedErrorCode.LOCERR_VARIABLE_DUPLICATE_NAME) {
              request.tries = (request.tries || 0) + 1;
              if (request.tries <= 20) {
                console.log("retring qlik");
                return request.retry();
              }
            }
            return this.Promise.reject(error);
          },
        },
      ],
    });
  };

  const setQlikConnection = async (docID) => {

    let response = await getQlikTicket(user.nickname);

    if (response === "no_access") {
      setUserAccess(response);
    } else {

      await connectQlik(response, docID);

      if (docID) {

        session.current.on("notification:*", async (event, authInfo) => {
          if (event === "OnMaxParallelSessionsExceeded") {
            console.log("came here with max session error");
            alert("Too many sessions open, please wait tleast 5 minutes before trying again");
          } else if (event === "OnAuthenticationInformation") {
            if (docID) {
              if (!authInfo.mustAuthenticate) {
                info("OnAuthenticationInformation", JSON.stringify(authInfo));
                window.logoutUri = authInfo.logoutUri;
                session.current.open().then(async (x) => {
                  let uid = await x.getUniqueID();
                  info("USID", uid);
                  let doc = await x.openDoc(docID);
                  setQDoc(doc);
                });
              }
            }
          } else if (event === "OnDisconnected") {
            console.log("disconnected");
          } else if (event === "OnConnected") {
            console.log("connected");
          }
        });

        session.current.on("traffic:*", (dir, data) => {
          console.log("traffic", dir, data);
        });

        session.current.on("closed", (data) => {
          // sessionStorage.clear();
          // window.location.href = "/";
          console.log("session closed", data);
        });
        session.current.open()
      } else {
        // getAppList().then((response) => {
        //   setAppList(response);
        // });
        session.current.open().then(async (a) => {
          let docList;
          try {
            docList = await a.getDocList({});
          } catch (ex) {
            session.current;
            docList = [];
            console.log(ex);
          }

          session.current.close().then((x) => {
            setAppList(docList);
          });
        });
      }
    }
  };

  const closeSession = () => {
    session.current.close()
  }

  return (
    <QlikContext.Provider value={{ qDoc, qlikApps, setQlikConnection, closeSession, userAccess }}>
      {children}
    </QlikContext.Provider>
  );
};