import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import PublicIcon from '@mui/icons-material/Public';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Flaky from '@mui/icons-material/Flaky';
const menuIcon = [
  {
    name: "Dashboard",
    icon: AnalyticsTwoToneIcon,
    link: "/dashboard",
  },
  {
    name: "Style Detail",
    icon: LocalMallIcon,
    link: "/style-detail",
  },
  {
    name: "Door Detail",
    icon: StoreMallDirectoryIcon,
    link: "/door-detail",
  },
  {
    name: "Geographic",
    icon: PublicIcon,
    link: "/geographic",
  },
  {
    name: "Trending",
    icon: TrendingUpIcon,
    link: "/trending",
  },
  {
    name: "AdHoc",
    icon: AssessmentIcon,
    link: "/adhoc",
  },
  {
    name: 'Data Availability',
    icon: Flaky,
    link: '/data-availability'
  },
  {
    name: 'Reports',
    icon: DescriptionIcon,
    link: '/report'
  }
]

export const menuItems = (val) => {
  return val.map((v) => {
    if (v.key) {
      let pathStr = v.key.split(" ").length > 1 ? `${v.key.split(" ")[0].toLowerCase()}-${v.key.split(" ")[1].toLowerCase()}` : v.key.toLowerCase();
      return {
        name: v.label,
        icon: menuIcon.filter((x) => x.name === v.key)[0].icon,
        link: `/${pathStr}`,
      };
    } else {
      let pathStr = v.split(" ").length > 1 ? `${v.split(" ")[0].toLowerCase()}-${v.split(" ")[1].toLowerCase()}` : v.toLowerCase();
      return {
        name: v,
        icon: menuIcon.filter((x) => x.name === v)[0].icon,
        link: `/${pathStr}`,
      };
    }
  });
};
