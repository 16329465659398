import React, { useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import timeOut from "./utils/timeOut";
import Route from "./layout/Route";
import { OverlayContext, UpdateContext } from "./contexts/context";
import useLogService from "./services/useLogService";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

function App() {
  timeOut();
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  let { update } = useContext(UpdateContext);
  let { OverlayAction } = useContext(OverlayContext);
  let { initialize, updateLogForTab } = useLogService();

  setTimeout(() => {
    if(sessionStorage.getItem("selApp")) {
      updateLogForTab();     
    }
  }, 5 * 60 * 1000);

  useEffect(() => {
    OverlayAction.start("Authenticating user...");
  }, []);

  React.useEffect(async () => {
    if (!isLoading) {
      if (isAuthenticated) {
        initialize();
        console.log("getting here");
        OverlayAction.stop();
      } else {
        sessionStorage.removeItem("selApp");
        loginWithRedirect();
      }
    }
  }, [isAuthenticated, isLoading]);

  return isAuthenticated && !isLoading && <Route />;
}
export default App;
