import { createContext, useState } from "react";

export const QlikObjectContext = createContext({});

export const QlikObjectProvider = ({ children }) => {
  const [qOjbectList, setQObjectList] = useState({});

  const setQlikObject = (props) => {
    let qIdStr = JSON.stringify(props.qId);

    if (!qOjbectList[qIdStr]) {
      setQObjectList((prev) => ({ ...prev, [qIdStr]: props }));
    }
  }

  return (
    <QlikObjectContext.Provider value={{ qOjbectList, setQlikObject }}>
      {children}
    </QlikObjectContext.Provider>
  );
};
