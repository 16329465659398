import React from "react";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import SkypadLogo from "../SkypadLogo/skypad_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "90vh",
    margin: "auto",
    alignItems: "center",
    fontSize: "1rem",
  },
}));

export default function CircularIndeterminate({ message }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={SkypadLogo} width="150" className="mb-5" />
      <CircularProgress style={{ color: "#54B948" }} className="mb-3" />
      <div style={{ display: "block" }}>{message}</div>
    </div>
  );
}
