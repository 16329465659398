import { useContext, useEffect, useRef } from "react";
import { QlikContext } from "../../contexts/QlikContext";

export const useSelectionObject = () => {
  const { qDoc } = useContext(QlikContext);
  const _isMounted = useRef(true);

  const clearSelections = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",");
        await valArr.forEach((element) => {
          qField.toggleSelect(element);
        });
      } else {
        await qField.clear();
      }
    } else {
      qDoc.abortModal({ qAccept: true });
      qDoc.clearAll();
    }
  };

  function selectBookmark(arr) {
    try {
      arr.forEach((xv) => {
        toggleSelections(xv[0], xv[1]);
      })
    } catch (ex) {
      console.log(ex);
    }
  }

  const bookmarkSetSelections = async (bookSelArr) => {
    qDoc.abortModal({ qAccept: true });
    await qDoc.clearAll();
    if (bookSelArr) {
      selectBookmark(bookSelArr);
    }
  };

  const toggleSelections1 = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",").map((x) => {
          return { qText: x };
        });
        qField.selectValues(valArr, false, false);
      }
    }
  };

  const toggleSelections = async (field, value) => {
    if (field) {
      const qField = await qDoc.getField(field);
      if (value) {
        let valArr = value.split(",");
        await valArr.forEach((element) => {
          qField.toggleSelect(element);
        });
      } else {
        await qField.clear();
      }
    } else {
      qDoc.clearAll();
    }
  };

  useEffect(() => () => (_isMounted.current = false), []);

  return {
    clearSelections,
    toggleSelections,
    toggleSelections1,
    bookmarkSetSelections,
  };
};

export async function setVariable(qDoc, durationVariable) {
  let x;
  try {
    x = await qDoc.getVariableByName("show_TrendsBy");
    await x.setProperties(durationVariable).then(() => true);
  } catch (e) {
    await qDoc.createSessionVariable(durationVariable);
  }
}
