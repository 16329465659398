export function isReportsUser(user) {

    let clientId = "";
    try {
        clientId = JSON.parse(sessionStorage.getItem("selApp")).clientId;
    } catch (e) {
        return false;
    }

    if (clientId === "no client id found" || clientId === "") {
        return false;
    }

    return user && user["http://skypad_user/groups"] && user["http://skypad_user/groups"].includes(`${clientId} ReportManager`);
}
