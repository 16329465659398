import { createContext, useState } from "react";
import SpinnerRetailer from "../components/Spinner/SpinnerRetailer";

export const OverlayContext = createContext({});

export const OverlayProvider = ({ children }) => {
  const [isStart, setAction] = useState(null);

  const OverlayAction = {
    start: (message) => {
      setAction(message);
    },
    stop: () => {
      setAction(null);
    },
  };

  return (
    <OverlayContext.Provider value={{ isStart, OverlayAction }}>
      {
        <>
          <div style={{ visibility: isStart ? "hidden" : "visible" }}>
            {" "}
            {children}{" "}
          </div>
          {isStart && <SpinnerRetailer message={isStart} />}
        </>
      }
    </OverlayContext.Provider>
  );
};
