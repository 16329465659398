import { useState, useEffect } from "react";

const UpdateService = ({ qDoc }) => {
  const [qLayout, setQLayout] = useState(null);

  var jsonObjStr = {
    qInfo: { qType: "last-update-object" },
    qHyperCubeDef: {
      qDimensions: [],
      qMeasures: [
        {
          qDef: {
            qDef: "[Reload Time]",
          },
        },
      ],
      qInitialDataFetch: [
        {
          qWidth: 1,
          qHeight: 1,
        },
      ],
    },
  };

  useEffect(() => {
    try {
      qDoc.createSessionObject(jsonObjStr).then((fields) => {
        fields.getLayout().then((_qLayout) => {
          var time = _qLayout.qHyperCube.qDataPages[0].qMatrix[0][0].qText;
          setQLayout({ time: time });
        });
      });
    } catch (ex) {
      console.log(ex);
    }
  }, []);

  return { qLayout };
};

export default UpdateService;
