import App from "./App";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { HelmetProvider } from "react-helmet-async";
import "nprogress/nprogress.css";
import { AUTH_CONFIG } from "../src/auth/auth0-variables";
import { SnackbarProvider } from "notistack";
import ThemeProvider1 from "./theme/ThemeProvider";
import {
  UpdateProvider,
  Auth0Provider,
  ApiProvider,
  QlikProvider,
  ConfigProvider,
  OverlayProvider,
  CurrentTimeFrameProvider,
  CurrencyProvider,
  QlikObjectProvider
} from "./contexts/provider";

ReactDOM.render(
  <HelmetProvider>
    <OverlayProvider>
      <ThemeProvider1>
        <UpdateProvider>
          <SnackbarProvider
            maxSnack={6}
            autoHideDuration={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Auth0Provider
              domain={AUTH_CONFIG.domain}
              clientId={AUTH_CONFIG.clientId}
              redirectUri={AUTH_CONFIG.callbackUrl}
              state={AUTH_CONFIG.clientName}
              audience="https://www.skypad-node-api.com"
              scope="openid profile email"
              responseType="token id_token"
            >
              <ApiProvider>
                <QlikProvider>
                  <QlikObjectProvider>
                    <CurrentTimeFrameProvider>
                      <CurrencyProvider>
                        <ConfigProvider>
                          <App />
                        </ConfigProvider>
                      </CurrencyProvider>
                    </CurrentTimeFrameProvider>
                  </QlikObjectProvider>
                </QlikProvider>
              </ApiProvider>
            </Auth0Provider>
          </SnackbarProvider>
        </UpdateProvider>
      </ThemeProvider1>
    </OverlayProvider>
  </HelmetProvider>,
  document.getElementById("root"),
);

serviceWorkerRegistration.register();
