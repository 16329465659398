import { Box, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, styled } from "@mui/styles";
import { menuItems } from "./items";
import { NavLink } from "react-router-dom";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { isReportsUser } from "src/utils/isReportsUser";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    "& a": {
      color: "#212529",

      [theme.breakpoints.down("xl")]: {
        "&.sevenMenuItems": {
          padding: "0 8px",
        },
        "&.eightMenuItems": {
          padding: "0 10px",
        },
        "&.nineMenuItems": {
          padding: "0 5px",
        },
        "& svg.sevenMenuItems": {
          fontSize: "2.4rem",
        },
        "& svg.eightMenuItems": {
          fontSize: "2.2rem",
        },
        "& svg.nineMenuItems": {
          fontSize: "2rem",
        },
      },
      [theme.breakpoints.up('lg') && theme.breakpoints.down('xl')]: {
        "&.sevenMenuItems": {
          padding: "0 9px",
        },
        "&.eightMenuItems": {
          padding: "0 10px",
        },
        "& svg.sevenMenuItems": {
          fontSize: "2.4rem",
        },
        "& svg.eightMenuItems": {
          fontSize: "2.2rem",
        },
        "& svg.nineMenuItems": {
          fontSize: "2rem",
        },
      },
      [theme.breakpoints.up('md') && theme.breakpoints.down('lg')]: {
        "&.sevenMenuItems": {
          padding: "0 4px",
        },
        "&.eightMenuItems": {
          padding: "0 5px",
        },
        "&.nineMenuItems": {
          padding: "0 5px",
        },
        "& svg.sevenMenuItems": {
          fontSize: "2.2rem",
        },
        "& svg.eightMenuItems": {
          fontSize: "2rem",
        },
        "& svg.nineMenuItems": {
          fontSize: "1.8rem",
        },
      },
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },

    "& .MuiSvgIcon-root": {
      fontSize: "2.6rem",
    },

    "& .MuiListItem-root": {
      transition: theme.transitions.create(["color", "fill"]),
      borderRadius: "6px",
      margin: "1px 0px",
      transition: "all 0.2s ease 0s",
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiListItem-indicators": {
        padding: theme.spacing(0, 2),

        "&  .MuiListItemText-root": {
          fontSize: "1rem",
        },
      },
    },
    "& .active": {
      color: theme.palette.success.dark,
      background: "#D7DEDF",
    },
  },
  listIcon: {
    minWidth: "28px",
  },
  listItemText: {
    [theme.breakpoints.down("xl")]: {
      "&.sevenMenuItems span": {
        fontSize: "15px",
      },
      "&.eightMenuItems span": {
        fontSize: "14px",
      },
      "&.nineMenuItems span": {
        fontSize: "13px",
      },
    },
    [theme.breakpoints.up('md') && theme.breakpoints.down('lg')]: {
      "&.sevenMenuItems span": {
        fontSize: "13px",
      },
    },
    "& :before": {
      height: "4px",
      width: "22px",
      opacity: 0,
      visibility: "hidden",
      display: "block",
      position: "absolute",
      bottom: "-10px",
      transition: "all .2s",
      borderRadius: theme.general.borderRadiusLg,
      content: "",
    },
  },
}));

function HeaderMenu() {
  const { t } = useTranslation();
  const { configVal } = useContext(ConfigContext);
  const { user } = useAuth0();
  const classes = useStyles();
  const [menuClassName, setMenuClassName] = useState(null);

  let tabs = configVal ? configVal.filter((x) => x.type === "Tab" && x.subtype === "Buttons")[0].value : [];

  if (isReportsUser(user)) {
    tabs = ["Reports"]
  }

  useEffect(() => {
    const menuList = document.getElementById("menuList");
    const listItems = menuList.querySelectorAll("span");

    if (listItems.length === 7) {
      setMenuClassName("sevenMenuItems");
    } else if (listItems.length === 8) {
      setMenuClassName("eightMenuItems");
    }
  }, []);
  return (
    <Box className={classes.headerWrapper}>
      <List
        component={Box}
        display="flex"
        sx={{ paddingTop: 0, paddingBottom: 0 }}
        id="menuList"
      >
        {menuItems(tabs).map((section, index) => (
          <ListItem
            key={`header-menu-${index}`}
            className={menuClassName !== null ? menuClassName : ""}
            component={NavLink}
            to={section.link}
          >
            <ListItemIcon
              key={`header-ListIcon-${index}`}
              className={classes.listIcon}
            >
              <section.icon
                key={`header-section-icon-${index}`}
                className={menuClassName !== null ? menuClassName : ""}
              ></section.icon>
            </ListItemIcon>
            <ListItemText
              key={`header-ListText-${index}`}
              primaryTypographyProps={{ noWrap: true }}
              primary={t(section.name)}
              className={
                menuClassName !== null
                  ? menuClassName + " " + classes.listItemText
                  : classes.listItemText
              }
              sx={{ "& span": { fontSize: "1rem" } }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default HeaderMenu;
