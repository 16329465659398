import { useRef } from "react";
import $ from "jquery";

let qObject = null;

const InitializeSelection = () => {
  let selections = null;
  const _isMounted = useRef(true);

  const update = async () => {
    try {
      const _qLayout = await qObject.getLayout();

      if (_isMounted.current) {
        selections = _qLayout && _qLayout.qSelectionObject.qSelections;
        let tempSelections =
          selections &&
          selections.map((x) => {
            return [
              x.qField,
              x.qSelectedFieldSelectionInfo.map((y) => y.qName).toString(),
            ];
          });

        let prevSel = JSON.parse(sessionStorage.getItem("selections"));

        sessionStorage.setItem("selections", JSON.stringify(tempSelections));

        if (tempSelections.length < prevSel.length) {
          let intersection = [];
          prevSel.map((x) => {
            if (!tempSelections.some((y) => y[0] === x[0])) {
              intersection = x;
            }
          });

          let test = $(".curr-selections .css-q8y8g7");
          test.toArray().forEach((x) => {
            if (x.children[0].children[0].innerHTML === intersection[0]) {
              x.parentNode.style.display = "none";
            }
          });
        } else if (tempSelections.length > prevSel.length) {
          let test = $(".curr-selections .css-q8y8g7");
          test.toArray().forEach((x) => {
            if (x.parentNode.style.display === "none") {
              x.parentNode.style.display = "block";
            }
          });
        }
      }

      let id = sessionStorage.getItem("pivotID");
      if (id) {
        const object = await qDoc.getObject(id);
        const layout = await object.getLayout();
        if (layout.qHyperCube.qError) {
          //await sleep(100);
          let elements = document.querySelectorAll('[data-tid="error-title"]');
          elements[0] && (elements[0].innerHTML = "Data is too large to process. Please filter down your data then try creating the report again.");
        } else {
        }
      }

    } catch (ex) {
      console.log(ex);
    }
  };

  async function initSelectionObject(qDoc) {
    try {
      const qProp = {
        qInfo: { qType: "SelectionObject" },
        qSelectionObjectDef: {},
      };
      qObject = await qDoc.createSessionObject(qProp);
      qObject.on("changed", () => {
        update();
      });
      update();
    } catch (ex) {
      console.log(ex);
    }
  }

  return {
    initSelectionObject
  };
};

export { InitializeSelection };