import React, { useMemo, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosInt from "../apis/configs/axiosConfig";
import { OverlayContext } from "./OverlayContext";
import { useSnackbar } from "notistack";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Tooltip } from "@mui/material";
import { text } from "../components/ExportSnackbar/text";
import { errorHandling } from "../services/ErrorHandlingService/ErrorService";

export const ApiContext = React.createContext();
export const ApiProvider = ({ children }) => {
  const navigateTo = (ex) => history.push({ pathname: "/error", state: ex });

  const [history, updateHistory] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const { OverlayAction } = useContext(OverlayContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (snackbarId) => (
    <>
      <Button
        onClick={() => closeSnackbar(snackbarId)}
        color="error"
        sx={{
          position: "absolute",
          bottom: "37px",
          background: "white",
          color: "#9F9F9F",
          right: "-7px",
          width: "17px",
          height: "17px",
          minWidth: 0,
          padding: 0,
          borderRadius: "10px",
          "&:hover": { background: "#DEDEDE" },
        }}
      >
        <Tooltip title={text.closeTooltip} placement="top-start">
          <HighlightOffIcon fontSize="medium" />
        </Tooltip>
      </Button>
    </>
  );

  const getAPI = async (config) => {

    let access_token_hook = await getAccessTokenSilently();
    config.headers = {
      authorization: `Bearer ${access_token_hook}`,
    };
    return new Promise((resolve, reject) => {
      axiosInt
        .request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((ex) => {
          if (ex.response.data == "ticket_log_error" || ex.response.data == "log_error") {
            resolve(true);
          } else {
            const errorPage = errorHandling(ex)
            if (errorPage) {
              navigateTo(errorPage);
            }
            else {
              let errorMsg = "";
              if (ex.response && (ex.response.data !== undefined)) {

                if (!ex.response.data.byteLength) {
                  errorMsg = ex.response.data;
                } else {
                  errorMsg = String.fromCharCode.apply(
                    null,
                    new Uint8Array(ex.response.data));
                }
              } else {
                errorMsg = ex.message
              };

              enqueueSnackbar(errorMsg, {
                action,
                variant: "error",
                autoHideDuration: 10000,
              });
              reject(ex);
            }
          }
          OverlayAction.stop();
        });
    });
  }


  const getQlikTicket = async (userId) => {
    let config = {
      url: "/qps",
      method: "get",
      params: {
        'opr': 'ticket',
        'userId': userId
      }
    };
    let response = await getAPI(config);
    return response;
  };

  const getImageb64 = async (imgList) => {
    let config = {
      url: "/gethtc",
      method: "post",
      data: {
        imgArr: imgList
      },
    };
    let response = await getAPI(config);
    return response;
  };

  const getConfig = async (app, id) => {
    let config = {
      url: "/configData1",
      method: "get",
      params: {
        app,
        id
      },
    };
    let response = await getAPI(config);
    return response;
  };

  const ExportAPI = async () => {
    let config = {
      url: "/export",
      method: "post",
      responseType: "arraybuffer",
    };
    let response = await getAPI(config);
    return response;
  };

  const BookmarkAPI = {
    get: async (id, email, nickname) => {
      let config = {
        url: "/getBookmark",
        method: "post",
        data: {
          id,
          email,
          nickname,
        },
      };
      let response = await getAPI(config);
      return response;
    },

    save: async (bookmarkObj, teamList) => {
      let config = {
        url: "/saveBookmark",
        method: "post",
        data: {
          bookmarkObj,
          teamList,
        },
      };
      let response = await getAPI(config);
      return response;
    },

    delete: async (id) => {
      let config = {
        url: "/deleteBookmark",
        method: "post",
        data: {
          id,
        },
      };
      let response = await getAPI(config);
      return response;
    },
  };

  const ReportsAPI = {
    get: async (ClientID, user) => {
      let config = {
        url: "/reports1",
        method: "get",
        params: {
          ClientID,
          user,
        },
      };
      let response = await getAPI(config);
      return response;
    },
    post: async (data) => {
      let config = {
        url: "/reports1",
        method: "post",
        data,
      };
      let response = await getAPI(config);
      return response;
    },
    delete: async (data) => {
      let clientID = data.ClientID;
      let username = data.UserName;
      let reportid = data.RecordID;
      let config = {
        url: "/reports1",
        method: "delete",
        params: {
          clientID,
          username,
          reportid,
          isDelete: "Yes"
        },
      };
      let response = await getAPI(config);
      return response;
    },
    send: async (data) => {
      let config = {
        url: "/sendReports",
        method: "post",
        data,
      };
      let response = await getAPI(config);
      return response;
    },

    download: async (data) => {
      let config = {
        url: "/downloadReport",
        method: "post",
        data,
        responseType: "arraybuffer",
      };
      let response = await getAPI(config);
      return response;
    },
  };

  const deleteSession = async () => {
    let config = {
      url: "/deleteSession",
      method: "get"
    };
    let response = await getAPI(config);
    return response;
  };

  const getUsers = async (appId) => {
    let config = {
      url: "/getUser",
      method: "get",
      params: {
        appId
      },
    };
    let response = await getAPI(config);
    return response;
  };

  const getMetric = async () => {
    let config = {
      url: "/getMetric",
      method: "get",
    };
    let response = await getAPI(config);
    return response;
  };

  const updateLog = async (log) => {
    let config = {
      url: "/addLog",
      method: "post",
      data: log,
    };
    let response = ''//await getAPI(config);
    return response;
  }
  const setTicketLog = async (data) => {
    let config = {
      url: "/ticketLog",
      method: "post",
      data,
    };
    let response = await getAPI(config);
    return response;
  };

  const getAppList = async () => {
    let config = {
      url: "/getAppList",
      method: "get",
    };
    let response = await getAPI(config);
    return response;
  };

  const contextValue = useMemo(() => {
    return {
      getQlikTicket,
      ExportAPI,
      getConfig,
      BookmarkAPI,
      updateHistory,
      ReportsAPI,
      deleteSession,
      getUsers,
      getMetric,
      getImageb64,
      updateLog,
      setTicketLog,
      getAppList
    };
  }, [
    getQlikTicket,
    ExportAPI,
    getConfig,
    BookmarkAPI,
    updateHistory,
    ReportsAPI,
    deleteSession,
    getUsers,
    getMetric,
    getImageb64,
    updateLog,
    setTicketLog,
    getAppList
  ]);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};
