const getDimensions = (dimensions, type, defaultTD) => {
  let configDimensions = [];

  if (!defaultTD) {
    defaultTD = JSON.parse(sessionStorage.getItem("navStates"))["defaultTD"]
  }
  let one = 0;
  if (type) {
    let test = dimensions.filter(x => x.subtype === "Default Dim" || x.subtype === "Select Dim" || x.subtype === "Dim");

    let testArr = test[0].value
    if (test[1]) {
      testArr = testArr.concat(test[1].value)
    }
    let testOrderArr = testArr.map(x => x.order)
    one = testOrderArr.reduce((a, b) => Math.max(a, b), -Infinity);
  }

  dimensions.map((x) => {
    if (x.subtype != "Timeframe" && x.subtype != "Report") {
      x.value.map((d) => {
        let colIndex = d.order;
        if (type) {
          let obj = {
            order: colIndex,
            localOrder: colIndex,
            key: d.key,
            type: "D",
            measure: d.key,
            displayName: d.label,
            alwaysVisible: false,
            includeInTD: false,
            pos: 1
          };

          if (x.subtype === "Default Dim") {
            obj.alwaysVisible = true;
            obj.subType = "defaultDim";
          }

          if (x.subtype === "Metrics") {
            obj.localOrder = colIndex + one;
            obj.order = (!d.key.includes('OH')) && d.args ? obj.order + defaultTD + 'M' : obj.order + 'M';
            obj.type = "M";
            obj.pos = 0;
          }

          if (x.subtype === "Metrics" && d.args) {
            obj.includeInTD = d.args
          }

          configDimensions.push(obj);
        } else {
          if (x.subtype !== "Select Dim") {
            configDimensions.push(getStatsStr(d, colIndex, x.subtype, defaultTD));
          }
        }
      });
    }
  });

  if (!type) {
    let dimConfig = configDimensions.filter(x => /^\d+$/.test(x)).sort((a, b) => a - b);

    let matConfig = configDimensions.filter(x => !/^\d+$/.test(x)).sort((a, b) => {
      (!/^\d+$/.test(a) ? a.replace(/[^0-9]/g, '') : a) - (!/^\d+$/.test(b) ? b.replace(/[^0-9]/g, '') : b)
    })
    configDimensions = dimConfig.concat(matConfig);
  }

  return configDimensions;
};

const getStatsStr = (d, colIndex, subType, defaultTD) => {
  if (d.args && !d.key.includes('OH')) {
    colIndex = (d.args.includes("T") || d.args.includes("NP")) && (!d.key.includes('OH')) ? colIndex + defaultTD : colIndex; //exception for % Ttl OH U to have timeframes

    let sortVal = d.args.split('|')[1];
    if (sortVal) {
      colIndex = sortVal === "D" ? '#' + colIndex : '$' + colIndex
    }
  }

  return subType.includes("Metrics") ? colIndex + 'M' : colIndex;
}

export default getDimensions;
