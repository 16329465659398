import React, { useContext } from 'react';
import { v4 } from 'uuid';
import { ApiContext } from '../contexts/ApiContext';

let log_unique_id = null;
let message_set = [];
let currentTab = null;


const logService = () => {
    const { updateLog } = useContext(ApiContext);

    const initialize = () => {
        log_unique_id = v4();
    };

    const info = (type, message) => {
        message_set.push({
            type: type,
            message: message,
            timestamp: Date.now()
        });
    };

    const error = (message, tab) => {
        message_set.push({
            type: "ERROR",
            message: message,
            timestamp: Date.now()
        });
        updateLogForTab(tab);
    }

    const updateLogForTab = async (tab) => {
        tab = tab || currentTab;
        if (message_set.length > 0) {
            let body = {
                tab: tab,
                log_unique_id: log_unique_id,
                messages: message_set
            }
            message_set = [];
            await updateLog(body);
        }
    };
    return {
        info,
        error,
        initialize,
        updateLogForTab
    };
};
export default logService;