import { Box, Button } from "@mui/material";
import HeaderMenu from "./Menu";
import HeaderUserbox from "./Userbox";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/system";
import { useContext } from "react";
import { ConfigContext } from "../../../contexts/ConfigContext";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    color: theme.header.textColor,
    right: 0,
    zIndex: 5,
    backgroundColor: "white",
    boxShadow: theme.header.boxShadow,
    position: "fixed",
    justifyContent: "space-between",
    width: "100%",
    padding: "5px",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
  },
  skypadLogo: {
    textAlign: "center",

    width: "205px",
    "& svg": {
      width: "inherit !important",
      fontSize: "2rem",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      // display: "none"
    },
  },
  headerMenu: {
    padding: "0 10px",
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
    flex: "20",
    width: "67%",
  },
}));

function Header() {
  const classes = useStyles();

  let { configVal } = useContext(ConfigContext);
  let skypadLogo = configVal.filter((x) => x.type === "Logo")[0]["value"][0];
  if(skypadLogo.text){
    skypadLogo = configVal.filter((x) => x.type === "Logo")[0]["value"][0].text;
  }

  return (
    <Box display="flex" alignItems="center" className={classes.headerWrapper}>
      <Box alignItems="center" sx={{ width: "215px" }}>
        <div className={classes.skypadLogo}>
          <a target="_blank" rel="noopener noreferrer">
            <img src={skypadLogo} width="auto" height="44" />
          </a>
        </div>
      </Box>
      <Box display="flex" alignItems="center" className={classes.headerMenu}>
        <HeaderMenu />
      </Box>
      {window.location.href.split("/").pop() === "data-availability" && (
          <Box>
            <Button variant="contained" size="small" onClick={() => window.open("https://skyalign.skypad.biz/", "_blank")} sx={{ color: "white" }}>SkyAlign</Button>
          </Box>
        )}
      <Box display="flex" sx={{ padding: "0 8px" }}>
        <HeaderUserbox />
      </Box>
    </Box>
  );
}

export default Header;
