import { useState, useContext, useEffect, memo } from "react";
import { Card } from "@mui/material";
import FilterGroup from "./FilterGroup";
import "./Sidebar.css";
import SelectionSearch from "../../components/SelectionSearch";
import { ConfigContext, QlikContext } from "../../contexts/context";
import { calendarGenericObj } from "../../services/qlikHyperCubeDefs/calendar-object";

// import 'bootstrap/dist/css/bootstrap.min.css';

const SidebarGroup = memo(function SidebarGroup() {
  const { configVal } = useContext(ConfigContext);
  const { qDoc } = useContext(QlikContext);
  const [expandedObject, setExpandedObject] = useState({
    expanded: false,
    expandedName: null,
    prevExpandedName: null,
  });
  const [selVal, setSelVal] = useState(null);
  let qObject;

  const update = async () => {
    const _qLayout = await qObject.getLayout();
    if (_qLayout.qHyperCube.qDataPages[0].qMatrix.length === 1) {
      var locObj = {
        wed: _qLayout.qHyperCube.qDataPages[0].qMatrix[0][0].qText,
        week: _qLayout.qHyperCube.qDataPages[0].qMatrix[0][1].qText,
        year: _qLayout.qHyperCube.qDataPages[0].qMatrix[0][2].qText,
        month: _qLayout.qHyperCube.qDataPages[0].qMatrix[0][3].qText,
      };
      setSelVal(locObj);
    } else
      setSelVal(null);
  };

  useEffect(async () => {
    const qProp = calendarGenericObj;
    qObject = await qDoc.createSessionObject(qProp);
    qObject.on("changed", () => {
      update();
    });
    update();
  }, []);

  let filterSelections = configVal.filter((x) => x.type === "Filter");

  const collapseAccordion = (menuSection) => (event, isExpanded) => {
    const prevExpandedName = expandedObject.expandedName;
    setExpandedObject({
      expanded: isExpanded ? menuSection : false,
      expandedName: event.currentTarget.id,
      prevExpandedName: prevExpandedName,
    });
  };

  let prevLength = 0;

  return (
    <div className="sidebar">
      <Card className="sidebarContent">
        <div
          style={{
            position: "sticky",
            top: 0,
            marginBottom: "3px",
            zIndex: 1,
          }}
        >
          <SelectionSearch />
        </div>
        {filterSelections.map((selectionGroup, i) => {
          let startIndex1 = prevLength;
          prevLength = selectionGroup.value.length + prevLength;
          return (
            <FilterGroup
              groupName={selectionGroup.subtype}
              filters={selectionGroup.value}
              startIndex={startIndex1}
              collapseAccordion={collapseAccordion(i)}
              expanded={expandedObject.expanded === i}
              expandedName={expandedObject.expandedName}
              prevExpandedName={expandedObject.prevExpandedName}
              selVal={selVal}
              key={`sidebar-group-${i}`}
            />
          );
        })}
      </Card>
    </div>
  );
})

export default SidebarGroup;
