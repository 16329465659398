import PropTypes from "prop-types";

const Authenticated = ({ children }) => {
  //const { isAuthenticated } = useAuth();

  // if (!isAuthenticated) {
  //   return <Redirect to="/login" />;
  // }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
