import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeCreator } from "./base";
import { StyledEngineProvider } from "@mui/material/styles";

export const ThemeContext = React.createContext(() => {});

const ThemeProvider1 = function (props) {
  const curThemeName = "PureLightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider1;
