export const calendarGenericObj = {
  qInfo: { qType: "calendar-object" },
  qHyperCubeDef: {
    qDimensions: [
      {
        qDef: {
          qFieldDefs: ["Week Ending Date"],
        },
      },
      {
        qDef: {
          qFieldDefs: ["Week #"],
        },
      },
      {
        qDef: {
          qFieldDefs: ["Year"],
        },
      },
      {
        qDef: {
          qFieldDefs: ["Month"],
        },
      },
    ],
    qInitialDataFetch: [
      {
        qWidth: 4,
        qHeight: 3,
      },
    ],
  },
};

export default { calendarGenericObj };
