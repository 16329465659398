const errorHandling = (ex) => {
    if (ex.response && ex.response.data && !ex.response.data.byteLength) {
        if (ex.response.data === "no_access" || ex.response.data.includes("QlikTicket")) {
            return "Issue verifying User's access!"
        }
        else if (ex.response.data.includes("config")) {
            return "Issue getting data from config file!"
        }
    }
    else {
        return false
    }
}

module.exports = {
    errorHandling,
}