import { PureLightTheme } from "./schemes/PureLightTheme";
import { DarkSpacesTheme } from "./schemes/DarkSpacesTheme";

export function themeCreator(theme) {
  return themeMap[theme];
}

const themeMap = {
  PureLightTheme,
  DarkSpacesTheme,
};
