export let jsonObjStr = {
  qInfo: { qType: "second-period-object" },
  initialProperties: {
    first: {
      qHyperCubeDef: {
        qDimensions: [
          {
            qDef: {
              qFieldDefs: ["Week Ending Date"],
              qSortCriterias: [
                {
                  qSortByExpression: -1,
                  qExpression: {
                    qv: "num(date(date#(['Week Ending Date'], 'MM/DD/YYYY'), 'MM/DD/YYYY'))",
                  },
                },
              ],
            },
          },
          {
            qDef: {
              qFieldDefs: ["weekID"],
              qSortCriterias: [
                {
                  qSortByNumeric: -1,
                },
              ],
            },
          },
        ],
        qInitialDataFetch: [
          {
            qWidth: 2,
            qHeight: 1,
          },
        ],
        qInterColumnSortOrder: [1,0],
      },
    },
  },
};

export default { jsonObjStr };
