import { Box, Card, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UpdateService from "./UpdateService";
import { useContext } from "react";
import { QlikContext } from "../../contexts/QlikContext";

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    borderRadius: 0,
    position: "absolute",
    bottom: 0,
    width: "100%",
    margin: 0,
    marginTop: theme.spacing(3),
    zIndex:1
  },
}));

const Footer = () => {
  const { qDoc } = useContext(QlikContext);
  const { qLayout } = UpdateService({ qDoc });
  const classes = useStyles();
  return (
    qLayout && (
      <Card className={classes.footerWrapper}>
        <Container maxWidth="xl">
          <Box
            py={0.5}
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            textAlign={{ xs: "center", md: "left" }}
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              {" "}
              Last Updated: {qLayout.time}
            </Typography>
            <Box>
              <Typography variant="subtitle1">
                {new Date().getFullYear()} &copy; SKY IT Group
              </Typography>
            </Box>
            <Typography sx={{ pt: { xs: 2, md: 0 } }} variant="subtitle1">
              Designed by skyitgroup.com
            </Typography>
          </Box>
        </Container>
      </Card>
    )
  );
};

export default Footer;
